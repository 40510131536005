import { useEffect, useRef, useState } from "react";
import "./Subscription.css";
import Loading from "../../../components/atoms/Loading/Loading";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  useBuyPlanStripeSubscriptionMutation,
  useCheckStripeSubscriptionMutation,
  useManageStripeSubscriptionMutation,
} from "../../../store/api/api";

type Plans =
  | "none"
  | "Trial"
  | "Start"
  | "Pro"
  | "Premium"
  | "Exclusive"
  | undefined;

type StripePlanTypes = {
  stripePlanStatus: boolean;
  plan: Plans | null;
};

function SubscriptionPage() {
  const { param } = useParams();

  const paymentSuccess = param === "sukces";
  const paymentFailure = param === "niepowodzenie";

  const effectRan = useRef(false);
  const [stripePlan, setStripePlan] = useState<StripePlanTypes>({
    stripePlanStatus: false,
    plan: null,
  });

  const [checkStripeSubscription, { data, isLoading, isSuccess }] =
    useCheckStripeSubscriptionMutation();

  const [
    manageStripeSubscription,
    {
      data: dataManageStripe,
      isLoading: isLoadingManagePlanStripe,
      isSuccess: isSuccessManagePlanStripe,
    },
  ] = useManageStripeSubscriptionMutation();

  const [
    buyPlanStripe,
    {
      data: dataPlanStripe,
      isLoading: isLoadingBuyPlanStripe,
      isSuccess: isSuccessBuyPlanStripe,
    },
  ] = useBuyPlanStripeSubscriptionMutation();

  const buyPlan = (choosenPlan: Plans) => {
    if (!choosenPlan) return;
    buyPlanStripe({ plan: choosenPlan });
  };
  const manageSubscription = () => {
    manageStripeSubscription();
  };

  useEffect(() => {
    if (effectRan.current === true || process.env.NODE_ENV !== "development") {
      checkStripeSubscription();
    }
    return () => {
      effectRan.current = true;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      const stripePlanStatus = data.stripePlanStatus as boolean;
      const plan = data.plan as Plans;
      const stripePlanState = {
        stripePlanStatus,
        plan,
      };
      setStripePlan(stripePlanState);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isSuccessBuyPlanStripe && dataPlanStripe) {
      window.location.href = dataPlanStripe.sessionUrl;
    }
  }, [isSuccessBuyPlanStripe, dataPlanStripe]);

  useEffect(() => {
    if (isSuccessManagePlanStripe && dataManageStripe) {
      window.location.href = dataManageStripe.sessionUrl;
    }
  }, [isSuccessManagePlanStripe, dataManageStripe]);

  useEffect(() => {
    if (paymentSuccess) {
      toast.success("Transakcja przebiegła pomyślnie!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (paymentFailure) {
      toast.error("Coś poszło nie tak!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [paymentSuccess, paymentFailure]);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div>
      {(isLoadingBuyPlanStripe || isLoadingManagePlanStripe) && <Loading />}
      <div className='subscriptions-container'>
        {stripePlan.plan === "Trial" && (
          <div className='plan-container'>
            <div>
              <p className='plan-active'>AKTYWNY</p>
              <p className='plan-title'>Test</p>
              <p className='plan-price'>Darmowy</p>
              <p className='plan-price-period'>90 dni</p>
              <p className='plan-cards-users'>3 konta w aplikacji</p>
              <p className='plan-cards-amount'>Obsługa do 5 kart</p>
            </div>
          </div>
        )}

        <div className='plan-container'>
          <div>
            {stripePlan.plan === "Start" && (
              <p className='plan-active'>AKTYWNY</p>
            )}
            <p className='plan-title'>Start</p>
            <p className='plan-price'>49zł</p>
            <p className='plan-price-period'>miesięcznie</p>
            <p className='plan-cards-users'>5 kont w aplikacji</p>
            <p className='plan-cards-amount'>Obsługa do 50 kart</p>
          </div>
          {stripePlan.plan === "Start" ? (
            <button
              onClick={() => manageSubscription()}
              className='plan-button plan-button-subscription'
            >
              Zarządzaj subskrypcją
            </button>
          ) : (
            <button onClick={() => buyPlan("Start")} className='plan-button'>
              Subskrybuj
            </button>
          )}
        </div>
        <div className='plan-container'>
          <div>
            {stripePlan.plan === "Pro" && (
              <p className='plan-active'>AKTYWNY</p>
            )}
            <p className='plan-title'>Pro</p>
            <p className='plan-price'>99zł</p>
            <p className='plan-price-period'>miesięcznie</p>
            <p className='plan-cards-users'>8 kont w aplikacji</p>
            <p className='plan-cards-amount'>Obsługa do 120 kart</p>
          </div>
          {stripePlan.plan === "Pro" ? (
            <button
              onClick={() => manageSubscription()}
              className='plan-button plan-button-subscription'
            >
              Zarządzaj subskrypcją
            </button>
          ) : (
            <button onClick={() => buyPlan("Pro")} className='plan-button'>
              Subskrybuj
            </button>
          )}
        </div>
        <div className='plan-container'>
          <div>
            {stripePlan.plan === "Premium" && (
              <p className='plan-active'>AKTYWNY</p>
            )}
            <p className='plan-title'>Premium</p>
            <p className='plan-price'>169zł</p>
            <p className='plan-price-period'>miesięcznie</p>
            <p className='plan-cards-users'>15 kont w aplikacji</p>
            <p className='plan-cards-amount'>Obsługa do 250 kart</p>
          </div>
          {stripePlan.plan === "Premium" ? (
            <button
              onClick={() => manageSubscription()}
              className='plan-button plan-button-subscription'
            >
              Zarządzaj subskrypcją
            </button>
          ) : (
            <button onClick={() => buyPlan("Premium")} className='plan-button'>
              Subskrybuj
            </button>
          )}
        </div>
        <div className='plan-container'>
          <div>
            {stripePlan.plan === "Exclusive" && (
              <p className='plan-active'>AKTYWNY</p>
            )}
            <p className='plan-title'>Exclusive</p>
            <p className='plan-price'>799zł</p>
            <p className='plan-price-period'>miesięcznie</p>
            <p className='plan-cards-users'>40 kont w aplikacji</p>
            <p className='plan-cards-amount'>
              Obsługa nielimitowanej ilości kart
            </p>
          </div>
          {stripePlan.plan === "Exclusive" ? (
            <button
              onClick={() => manageSubscription()}
              className='plan-button plan-button-subscription'
            >
              Zarządzaj subskrypcją
            </button>
          ) : (
            <button
              onClick={() => buyPlan("Exclusive")}
              className='plan-button'
            >
              Subskrybuj
            </button>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default SubscriptionPage;
