import { Routes, Route, Navigate } from "react-router-dom";
import RequireAuth from "./auth/RequireAuth";
import Unauthorized from "./pages/Unauthorized/Unauthorized";
import LoginForm from "./pages/Auth/LoginForm/LoginForm";
import RegisterForm from "./pages/Auth/RegisterForm/RegisterForm";
import RemindPassword from "./pages/Auth/RemindPassword/RemindPassword";
import RegisterSuccess from "./pages/Auth/RegisterSuccess/RegisterSuccess";
import RefreshVerificationToken from "./pages/Auth/RefreshVerificationToken/RefreshVerificationToken";
import RefreshVerificationTokenSuccess from "./pages/Auth/RefreshVerificationTokenSuccess/RefreshVerificationTokenSuccess";
import RemindPasswordSuccess from "./pages/Auth/RemindPasswordSuccess/RemindPasswordSuccess";
import PersistLogin from "./pages/Auth/PersistLogin/PersistLogin";
import Missing from "./pages/Missing/Missing/Missing";
import SubscriptionPage from "./pages/Dashboard/SubscriptionPage/SubscriptionPage";
import AssignedCards from "./pages/Dashboard/AssignedCards/AssignedCards";
import Users from "./pages/Dashboard/Users/Users";
import SubscriptionPageFailure from "./pages/Dashboard/SubscriptionPageFailure/SubscriptionPageFailure";
import SubscriptionPageSuccess from "./pages/Dashboard/SubscriptionPageSuccess/SubscriptionPageSuccess";

const ROLES = {
  Admin: "7645",
  Owner: "2456",
};

function App() {
  return (
    <Routes>
      <Route path='/'>
        public routes
        <Route path='logowanie' element={<LoginForm />} />
        <Route path='logowanie/:param' element={<LoginForm />} />
        <Route path='zaloz-konto' element={<RegisterForm />} />
        <Route path='weryfikacja-konta/:param' element={<RegisterSuccess />} />
        <Route path='nowy-token' element={<RefreshVerificationToken />} />
        <Route
          path='nowy-token-sukces'
          element={<RefreshVerificationTokenSuccess />}
        />
        <Route path='przypomnij-haslo' element={<RemindPassword />} />
        <Route
          path='przypomnij-haslo/:param'
          element={<RemindPasswordSuccess />}
        />
        <Route path='brak-dostepu' element={<Unauthorized />} />
        {/* protect routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={ROLES.Owner} />}>
            <Route path='/' element={<Navigate replace to='/subskrypcja' />} />
            <Route path='/subskrypcja' element={<SubscriptionPage />} />
            <Route
              path='/subskrypcja/sukces'
              element={<SubscriptionPageSuccess />}
            />
            <Route
              path='/subskrypcja/niepowodzenie'
              element={<SubscriptionPageFailure />}
            />
            <Route path='/przypisane-karty' element={<AssignedCards />} />{" "}
            <Route path='/uzytkownicy' element={<Users />} />
          </Route>
          <Route path='*' element={<Missing />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
