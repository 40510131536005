import "./Logo.css";
import { Link } from "react-router-dom";

type Props = { color: "light" | "dark" | "gradient" };

function Logo({ color }: Props) {
  return (
    <div className='logo-wrapper'>
      <Link to='/'>
        <h1 className={`logo-text ${color}`}>TBON</h1>
      </Link>
    </div>
  );
}

export default Logo;
