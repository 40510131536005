import { useParams } from "react-router-dom";
import Logo from "../../../components/atoms/Logo/Logo";
import "./RegisterSuccess.css";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Loading from "../../../components/atoms/Loading/Loading";
import { nanoid } from "@reduxjs/toolkit";
import { isApiError } from "../../../utils/apiErorr";
import { useResendVeryficationLinkMutation } from "../../../store/api/api";

function RegisterSuccess() {
  const [resendVeryficationLink, { isSuccess, isLoading, error }] =
    useResendVeryficationLinkMutation();

  const { param } = useParams();

  const submitButton = async () => {
    if (param) {
      resendVeryficationLink(param);
    }
  };
  const apiError = isApiError(error);
  return (
    <section className='form-section'>
      <div className='logo-container'>
        <Logo color={"dark"} />
      </div>
      <div className='container-form'>
        {isLoading && <Loading />}
        <div className={apiError ? "errors" : "offscreen"}>
          {apiError &&
            apiError.errors.map((singleError: string) => {
              return <p key={nanoid()}>- {singleError}</p>;
            })}
        </div>
        <div className='icon-container'>
          <FontAwesomeIcon size='2x' icon={faCheck} color='#007185' />
        </div>
        <h2 className='register-success-title'>
          Sukces! Konto zostało pomyślnie utworzone!
        </h2>
        <p className='register-success-subtitle'>
          Na twój email został wysłany link weryfikacyjny. Po jego kliknięciu,
          zostniesz przeniesiony na stronę logowania.
        </p>
        <p className='register-success-subtitle'>
          Jeśli email nie doszedł, kliknij w poniższy link, a wyślemy maila
          jeszcze raz!
        </p>
        <button onClick={submitButton} className='register-success-button'>
          Wyślij maila jeszcze raz!
        </button>
        {isSuccess && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faCheck} color='#007185' />
            <p className='register-success-message'>
              Sukces! Email został wysłany jeszcze raz!
            </p>
          </div>
        )}
      </div>
    </section>
  );
}

export default RegisterSuccess;
