import { Outlet } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useRefreshTokenMutation } from "../../../store/api/api";
import Loading from "../../../components/atoms/Loading/Loading";
import { setAuth } from "../../../store/state/authSlice";

const PersistLogin = () => {
  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector((state) => state.auth);

  const effectRan = useRef(false);

  const [isWaitingForToken, setIsWaitingForToken] = useState(true);

  const [refresh] = useRefreshTokenMutation();

  useEffect(() => {
    if (effectRan.current === true || process.env.NODE_ENV !== "development") {
      const verifyRefreshToken = async () => {
        try {
          const response = await refresh();

          if (response && "data" in response) {
            const { token, roles, email } = response.data;
            console.log("Refresh token: " + token);

            const newAuth = {
              roles: roles,
              accessToken: token,
              email: email,
            };
            dispatch(setAuth(newAuth));
          }
        } catch (err) {
          console.error(err);
        } finally {
          setIsWaitingForToken(false);
        }
      };

      !accessToken ? verifyRefreshToken() : setIsWaitingForToken(false);
    }

    return () => {
      effectRan.current = true;
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isWaitingForToken ? (
        <div>
          <Loading />
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;
