import React from "react";

function SubscriptionPageSuccess() {
  return (
    <div>
      <p>Super!</p>
      <p>Pomyślnie zakupiłeś subksrypcję.</p>
      <p>Dziękujemy!</p>
    </div>
  );
}

export default SubscriptionPageSuccess;
