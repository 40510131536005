import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Auth {
  email: string;
  roles: string;
  accessToken: string;
}

const initialState: Auth = {
  email: "",
  roles: "",
  accessToken: "",
};

export const userData = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<Auth>) => {
      state.email = action.payload.email;
      state.roles = action.payload.roles;
      state.accessToken = action.payload.accessToken;
    },
    logOut: (state) => {
      state.email = "";
      state.roles = "";
      state.accessToken = "";
    },
  },
});

export const { setAuth, logOut } = userData.actions;

export default userData.reducer;
