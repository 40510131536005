import { useAppSelector } from "../../store/hooks";
import "./Layout.css";
type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  return <main className={accessToken ? "app" : ""}>{children}</main>;
};

export default Layout;
