import Logo from "../../../components/atoms/Logo/Logo";
import "./RefreshVerificationTokenSuccess.css";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function RefreshVerificationTokenSuccess() {
  return (
    <section className='form-section'>
      <div className='logo-container'>
        <Logo color={"dark"} />
      </div>
      <div className='container-form'>
        <div className='icon-container'>
          <FontAwesomeIcon size='2x' icon={faCheck} color='#007185' />
        </div>
        <h2 className='refresh-token-success-title'>Sukces!</h2>
        <p>
          Na twój email został wysłany link weryfikacyjny. Po jego kliknięciu
          zostniesz przeniesiony na stronę logowania.
        </p>
      </div>
    </section>
  );
}

export default RefreshVerificationTokenSuccess;
