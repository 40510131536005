import { useRef, useState, useEffect } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import "../LoginForm/Form.css";
import Logo from "../../../components/atoms/Logo/Logo";
import Loading from "../../../components/atoms/Loading/Loading";
import { nanoid } from "nanoid";
import { useCreateUserMutation } from "../../../store/api/api";
import { isApiError } from "../../../utils/apiErorr";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PWD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{7,20}$/;

const RegisterForm = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const errRef = useRef<HTMLDivElement>(null);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);

  const [validationErrMsg, setValidationErrMsg] = useState("");

  const [createUser, { data, isLoading, error, isSuccess }] =
    useCreateUserMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setValidationErrMsg("");
  }, [email, pwd, matchPwd]);

  useEffect(() => {
    if (isSuccess && data) {
      setPwd("");
      setMatchPwd("");
      const token = data.verificationToken;
      navigate(`/weryfikacja-konta/${token}`);
    }
  }, [isSuccess, data, navigate]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const v1 = EMAIL_REGEX.test(email);
    const v2 = PWD_REGEX.test(pwd);
    if (!v1 || !v2) {
      setValidationErrMsg("Niewłaściwe dane!");
      return;
    }
    createUser({
      email,
      password: pwd,
      confirmPassword: matchPwd,
    });
  };

  const apiError = isApiError(error);

  return (
    <>
      <section className='form-section'>
        <div className='logo-container'>
          <Logo color={"dark"} />
        </div>
        <div className='container-form'>
          {isLoading && <Loading />}
          <div className={apiError ? "errors" : "offscreen"}>
            {apiError &&
              apiError.errors.map((singleError) => {
                return <p key={nanoid()}>- {singleError}</p>;
              })}
          </div>
          <p
            ref={errRef}
            className={validationErrMsg ? "errmsg" : "offscreen"}
            aria-live='assertive'
          >
            {validationErrMsg}
          </p>
          <h1 className='title-form'>Załóż konto</h1>
          <form className='form' onSubmit={(e) => handleSubmit(e)}>
            <label htmlFor='email'>
              Adres email:
              <FontAwesomeIcon
                icon={faCheck}
                className={validEmail ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validEmail || !email ? "hide" : "invalid"}
              />
            </label>
            <input
              type='email'
              id='email'
              ref={emailRef}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              aria-invalid={validEmail ? "false" : "true"}
              aria-describedby='emailnote'
              onFocus={() => setEmailFocus(true)}
              onBlur={() => setEmailFocus(false)}
              className='input-form'
              placeholder='Wpisz adres email...'
            />
            <p
              id='emailnote'
              className={
                emailFocus && email && !validEmail
                  ? "instructions"
                  : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              Email musi być w formacie xxx@yyy.com
            </p>

            <label htmlFor='password'>
              Hasło:
              <FontAwesomeIcon
                icon={faCheck}
                className={validPwd ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validPwd || !pwd ? "hide" : "invalid"}
              />
            </label>
            <input
              type='password'
              id='password'
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
              aria-invalid={validPwd ? "false" : "true"}
              aria-describedby='pwdnote'
              onFocus={() => setPwdFocus(true)}
              onBlur={() => setPwdFocus(false)}
              className='input-form'
              placeholder='Wpisz hasło...'
            />
            <p
              id='pwdnote'
              className={
                pwdFocus && pwd && !validPwd ? "instructions" : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              <br />
              Hasło musi zawierać przynajmniej 7 znaków (maksymalnie 20),
              <br />
              Conajmniej jedną dużą literę,
              <br />
              Conajmniej jedną liczbę,
              <br />
              Conajmniej jeden znak spejcalny
              <br />
            </p>
            <label htmlFor='confirm_pwd'>
              Potwierdź hasło:
              <FontAwesomeIcon
                icon={faCheck}
                className={validMatch && matchPwd ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validMatch || !matchPwd ? "hide" : "invalid"}
              />
            </label>
            <input
              type='password'
              id='confirm_pwd'
              onChange={(e) => setMatchPwd(e.target.value)}
              value={matchPwd}
              required
              aria-invalid={validMatch ? "false" : "true"}
              aria-describedby='confirmnote'
              className='input-form'
              placeholder='Wpisz ponownie to samo hasło...'
            />

            <button
              className='button-form'
              disabled={!validEmail || !validPwd || !validMatch ? true : false}
            >
              ZAŁÓŻ DARMOWE KONTO
            </button>
          </form>
          <div className='regulations-wrapper'>
            <p>
              Rejestrując się potwierdzasz, że zapoznałeś się z{" "}
              <span>
                <a
                  className='regulations-link'
                  href='https://www.tbon.pl/regulamin'
                >
                  regulaminem TBON
                </a>
              </span>{" "}
              oraz akceptujesz jego warunki.
            </p>
          </div>
          <div className='account-container'>
            <p>
              Masz już konto?
              <span className='line'>
                <Link className='account-button' to='/logowanie'>
                  Zaloguj się!
                </Link>
              </span>
            </p>
          </div>
        </div>
      </section>
      )
    </>
  );
};

export default RegisterForm;
