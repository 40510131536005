import "./Missing.css";
function Missing() {
  return (
    <div className='missing-container'>
      <p>Ta strona nie istnieje!</p>
    </div>
  );
}

export default Missing;
