import { useRef, useState, useEffect } from "react";

import "../LoginForm/Form.css";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../../components/atoms/Logo/Logo";

import Loading from "../../../components/atoms/Loading/Loading";
import { nanoid } from "@reduxjs/toolkit";
import { isApiError } from "../../../utils/apiErorr";
import { useResetPasswordMutation } from "../../../store/api/api";

function RemindPassword() {
  const emailRef = useRef<HTMLInputElement>(null);
  const [resetPassword, { isSuccess, isLoading, error }] =
    useResetPasswordMutation();
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    resetPassword(email);
  };

  const apiError = isApiError(error);

  return (
    <section className='form-section'>
      <div className='logo-container'>
        <Logo color='dark' />
      </div>
      <div className='container-form'>
        {isLoading && <Loading />}
        <div className={apiError ? "errors" : "offscreen"}>
          {apiError &&
            apiError.errors.map((singleError) => {
              return <p key={nanoid()}>- {singleError}</p>;
            })}
        </div>
        <h1 className='title-form'>Nie pamiętasz hasła?</h1>
        <h2 className='subtitle-form'>
          Nic strasznego! Wpisz swój email, a my wyślemy do Ciebie link do
          ustawienia nowego.
        </h2>
        <form className='form' onSubmit={handleSubmit}>
          <label className='label-form' htmlFor='email'>
            Adres email:
          </label>
          <input
            type='text'
            id='email'
            placeholder='Wpisz adres email'
            ref={emailRef}
            autoComplete='off'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
            className='input-form'
          />

          <button className='button-form'>RESETUJ HASŁO</button>
          {isSuccess && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon icon={faCheck} color='#007185' />
              <p className='register-success-message'>
                Sukces! Email został wysłany! Wejdź na swoją skrzynkę i zresetuj
                hasło.
              </p>
            </div>
          )}
        </form>
      </div>
    </section>
  );
}

export default RemindPassword;
