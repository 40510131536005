import { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import "./Form.css";
import { useAppDispatch } from "../../../store/hooks";
import { setAuth } from "../../../store/state/authSlice";
import Logo from "../../../components/atoms/Logo/Logo";
import Loading from "../../../components/atoms/Loading/Loading";

import { nanoid } from "@reduxjs/toolkit";

import { useSignInMutation } from "../../../store/api/api";
import { isApiError } from "../../../utils/apiErorr";

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const [signIn, { isLoading, error }] = useSignInMutation();

  const { param } = useParams();
  const isAfterSuccessfulVerification =
    param === "weryfikacja-sukces" ? true : false;

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const emailRef = useRef<HTMLInputElement>(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await signIn({ email, password: password }).unwrap();
      const accessToken = response.token;
      const roles = response.roles;
      dispatch(setAuth({ email, roles, accessToken }));
      setEmail("");
      setPassword("");
      navigate(from, { replace: true });
    } catch (error: any) {}
  };

  const apiError = isApiError(error);

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  return (
    <section className='form-section'>
      <div className='logo-container'>
        <Logo color={"dark"} />
      </div>

      <div className={apiError ? "errors" : "offscreen"}>
        {apiError &&
          apiError.errors.map((singleError) => {
            return <p key={nanoid()}>- {singleError}</p>;
          })}
      </div>
      {isAfterSuccessfulVerification && (
        <h2 className='success-info'>
          Konto zostało pomyślnie zweryfikowane. Zaloguj się poniżej!
        </h2>
      )}
      <div className='container-form'>
        {isLoading && <Loading />}
        <h1 className='title-form'>Logowanie</h1>
        <form className='form' onSubmit={handleSubmit}>
          <label className='label-form' htmlFor='email'>
            Adres email:
          </label>
          <input
            type='text'
            id='email'
            placeholder='Wpisz adres email...'
            ref={emailRef}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
            className='input-form'
          />

          <label className='label-form' htmlFor='password'>
            Hasło:
          </label>
          <input
            type='password'
            id='password'
            placeholder='Wpisz hasło...'
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
            className='input-form'
          />
          <div className='forgot-password-container'>
            <Link className='forgot-password-button' to='/przypomnij-haslo'>
              Przypomnij hasło
            </Link>
          </div>
          <button className='button-form'>ZALOGUJ</button>
        </form>
        <div className='account-container'>
          <p>
            Nie masz jeszcze konta?
            <span className='line'>
              <Link className='account-button' to='/zaloz-konto'>
                Załóż teraz!
              </Link>
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
