import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../store/hooks";

const RequireAuth = ({ allowedRoles }: any) => {
  const auth = useAppSelector((state) => state.auth);

  const location = useLocation();
  return auth.roles.includes(allowedRoles) ? (
    <Outlet />
  ) : auth.email ? (
    <Navigate to='/brak-dostepu' state={{ from: location }} replace />
  ) : (
    <Navigate to='/logowanie' state={{ from: location }} replace />
  );
};

export default RequireAuth;
