import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiEndpoint } from "./apiEndpoint";
import {
  AddUserResponse,
  AssigendCardsResponse,
  BuyPlanStripeSubscriptionQuery,
  BuyPlanStripeSubscriptionResponse,
  ChangePasswordQuery,
  ChangePasswordResponse,
  CheckStripeSubscriptionResponse,
  CreateUserQuery,
  CreateUserResponse,
  DeleteUserQuery,
  DeleteUserResponse,
  FreshVeryficationLinkResponse,
  GetUsersResponse,
  ManageStripeSubscriptionResponse,
  RefreshTokenResponse,
  ResendVeryficationLinkResponse,
  ResetPasswordResponse,
  SignInQuery,
  SignInResponse,
} from "./types";
import { RootState } from "..";
import { logOut, setAuth } from "../state/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: apiEndpoint,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReAuth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result && result.error && result.error.status === 403) {
    const refreshResult = await baseQuery("/refresh-token", api, extraOptions);
    if (refreshResult && refreshResult.data) {
      console.log(refreshResult);
      const { token, email, roles } =
        refreshResult.data as RefreshTokenResponse;
      api.dispatch(
        setAuth({
          accessToken: token,
          email: email,
          roles: roles,
        })
      );
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithReAuth,
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    signIn: builder.mutation<SignInResponse, SignInQuery>({
      query: (authData) => ({
        url: "/sign-in",
        method: "POST",
        body: authData,
      }),
    }),
    createUser: builder.mutation<CreateUserResponse, CreateUserQuery>({
      query: (userData) => ({
        url: "/create-user",
        method: "POST",
        body: userData,
      }),
    }),
    refreshToken: builder.mutation<RefreshTokenResponse, void>({
      query: () => ({
        url: "/refresh-token",
        method: "GET",
      }),
    }),
    assignedCards: builder.query<AssigendCardsResponse, void>({
      query: () => ({
        url: "/assigned-cards",
      }),
    }),
    freshVeryficationLink: builder.mutation<
      FreshVeryficationLinkResponse,
      string
    >({
      query: (param) => ({
        url: `/fresh-verify-user-token/${param}`,
        method: "GET",
      }),
    }),
    resendVeryficationLink: builder.mutation<
      ResendVeryficationLinkResponse,
      string
    >({
      query: (param) => ({
        url: `/resend-verification-link/${param}`,
        method: "GET",
      }),
    }),
    resetPassword: builder.mutation<ResetPasswordResponse, string>({
      query: (email) => ({
        url: `/reset-password`,
        method: "POST",
        body: { email },
      }),
    }),
    changePassword: builder.mutation<
      ChangePasswordResponse,
      ChangePasswordQuery
    >({
      query: (data) => ({
        url: `/change-password/${data.param}`,
        method: "POST",
        body: {
          password: data.password,
          confirmPassword: data.confirmPassword,
        },
      }),
    }),
    checkStripeSubscription: builder.mutation<
      CheckStripeSubscriptionResponse,
      void
    >({
      query: () => ({
        url: "/stripe/check-subscription",
        method: "GET",
      }),
    }),
    manageStripeSubscription: builder.mutation<
      ManageStripeSubscriptionResponse,
      void
    >({
      query: () => ({
        url: "/stripe/manage-subscription",
        method: "GET",
      }),
    }),
    buyPlanStripeSubscription: builder.mutation<
      BuyPlanStripeSubscriptionResponse,
      BuyPlanStripeSubscriptionQuery
    >({
      query: (stripeData) => ({
        url: "/stripe/subscribe/plan",
        method: "POST",
        body: stripeData,
      }),
    }),
    getUsers: builder.query<GetUsersResponse, void>({
      query: () => ({
        url: `users`,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),
    addUser: builder.mutation<AddUserResponse, CreateUserQuery>({
      query: (userData) => ({
        url: `add-user`,
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUser: builder.mutation<DeleteUserResponse, DeleteUserQuery>({
      query: (userData) => ({
        url: `delete-user`,
        method: "DELETE",
        body: userData,
      }),
      invalidatesTags: ["Users"],
    }),
    logOut: builder.mutation<any, void>({
      query: () => ({
        url: "/logout",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useCreateUserMutation,
  useSignInMutation,
  useRefreshTokenMutation,
  useResendVeryficationLinkMutation,
  useFreshVeryficationLinkMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useCheckStripeSubscriptionMutation,
  useManageStripeSubscriptionMutation,
  useBuyPlanStripeSubscriptionMutation,
  useLogOutMutation,
  useAddUserMutation,
  useDeleteUserMutation,
  useAssignedCardsQuery,
  useGetUsersQuery,
} = api;
