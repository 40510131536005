import Loading from "../../../components/atoms/Loading/Loading";
import { useAssignedCardsQuery } from "../../../store/api/api";
import "./AssignedCards.css";
import { nanoid } from "@reduxjs/toolkit";
import dayjs from "dayjs";

function AssignedCards() {
  const { data: cards, isLoading } = useAssignedCardsQuery();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className='assigendCards-container'>
      <p>Przypisane karty:</p>
      {cards &&
        cards.assignedCards &&
        cards.assignedCards.length > 0 &&
        cards.assignedCards.map((card) => {
          return (
            <div key={nanoid()} className='card-container'>
              <div>
                <p>Wartość: {card.value} zł</p>
                <p>
                  Data ważności:{" "}
                  {dayjs(card.cardExpirationDate).format("DD/MM/YYYY")}
                </p>
                {card.cardName && <p>Imię: {card?.cardName}</p>}
                {card.cardEmail && <p>Email: {card?.cardEmail}</p>}
                {card.cardPhoneNumber && (
                  <p>Numer telefonu: {card?.cardPhoneNumber}</p>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default AssignedCards;
