import { useRef, useState, useEffect } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import "../LoginForm/Form.css";
import Logo from "../../../components/atoms/Logo/Logo";
import Loading from "../../../components/atoms/Loading/Loading";
import { nanoid } from "@reduxjs/toolkit";
import { isApiError } from "../../../utils/apiErorr";
import { useChangePasswordMutation } from "../../../store/api/api";

const PWD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{7,20}$/;

function RemindPasswordSuccess() {
  const errRef = useRef<HTMLDivElement>(null);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);

  const [validationErrMsg, setVSalidationErrMsg] = useState("");

  const [changePassword, { isSuccess, isLoading, error }] =
    useChangePasswordMutation();

  const navigate = useNavigate();
  const { param } = useParams();

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setVSalidationErrMsg("");
  }, [pwd, matchPwd]);

  useEffect(() => {
    if (isSuccess) {
      setPwd("");
      setMatchPwd("");
      navigate(`/logowanie`, { replace: true });
    }
  }, [isSuccess, navigate]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const v1 = PWD_REGEX.test(pwd);
    if (!v1) {
      setVSalidationErrMsg("Niewłaściwe dane!");
      return;
    }

    if (param) {
      const data = { param, password: pwd, confirmPassword: matchPwd };
      changePassword(data);
    }
  };
  const apiError = isApiError(error);
  return (
    <>
      (
      <section className='form-section'>
        <div className='logo-container'>
          <Logo color={"dark"} />
        </div>
        <div className='container-form'>
          {isLoading && <Loading />}
          <div className={apiError ? "errors" : "offscreen"}>
            {apiError &&
              apiError.errors.map((singleError: string) => {
                return <p key={nanoid()}>- {singleError}</p>;
              })}
          </div>
          <p
            ref={errRef}
            className={validationErrMsg ? "errmsg" : "offscreen"}
            aria-live='assertive'
          >
            {validationErrMsg}
          </p>
          <h1 className='title-form'>Zresetuj hasło</h1>
          <form className='form' onSubmit={(e) => handleSubmit(e)}>
            <label htmlFor='password'>
              Nowe hasło:
              <FontAwesomeIcon
                icon={faCheck}
                className={validPwd ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validPwd || !pwd ? "hide" : "invalid"}
              />
            </label>
            <input
              type='password'
              id='password'
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
              aria-invalid={validPwd ? "false" : "true"}
              aria-describedby='pwdnote'
              onFocus={() => setPwdFocus(true)}
              onBlur={() => setPwdFocus(false)}
              className='input-form'
              placeholder='Wpisz nowe hasło...'
            />
            <p
              id='pwdnote'
              className={
                pwdFocus && pwd && !validPwd ? "instructions" : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              <br />
              Hasło musi zawierać przynajmniej 7 znaków (maksymalnie 20),
              <br />
              Conajmniej jedną dużą literę,
              <br />
              Conajmniej jedną liczbę,
              <br />
              Conajmniej jeden znak spejcalny
              <br />
            </p>
            <label htmlFor='confirm_pwd'>
              Potwierdź hasło:
              <FontAwesomeIcon
                icon={faCheck}
                className={validMatch && matchPwd ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validMatch || !matchPwd ? "hide" : "invalid"}
              />
            </label>
            <input
              type='password'
              id='confirm_pwd'
              onChange={(e) => setMatchPwd(e.target.value)}
              value={matchPwd}
              required
              aria-invalid={validMatch ? "false" : "true"}
              aria-describedby='confirmnote'
              className='input-form'
              placeholder='Wpisz ponownie nowe hasło...'
            />

            <button
              className='button-form'
              disabled={!validPwd || !validMatch ? true : false}
            >
              Zresetuj hasło
            </button>
          </form>
        </div>
      </section>
      )
    </>
  );
}

export default RemindPasswordSuccess;
