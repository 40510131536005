import { useLogOutMutation } from "../../../store/api/api";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { logOut } from "../../../store/state/authSlice";
import Logo from "../../atoms/Logo/Logo";
import "./NavBar.css";
import { NavLink } from "react-router-dom";

function NavBar() {
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const email = useAppSelector((state) => state.auth.email);
  const [logOutUser] = useLogOutMutation();
  const dispatch = useAppDispatch();

  const handleLogOut = async () => {
    try {
      await logOutUser();
      dispatch(logOut());
    } catch (err) {
      console.log(err);
    }
  };
  if (!accessToken) return null;

  return (
    <div className='navbar'>
      <Logo color={"gradient"} />
      <ul className='nav-links'>
        {email && (
          <li className='nav-link-item'>
            <p className='nav-link-email'>{email}</p>
          </li>
        )}
        <li className='nav-link-item'>
          <NavLink
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            to='/subskrypcja'
          >
            Subskrypcja
          </NavLink>
        </li>
        <li className='nav-link-item'>
          <NavLink
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            to='/przypisane-karty'
          >
            Przypisane karty
          </NavLink>
        </li>
        <li className='nav-link-item'>
          <NavLink
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            to='/uzytkownicy'
          >
            Użytkownicy
          </NavLink>
        </li>
        <li className='nav-link-item'>
          <button onClick={() => handleLogOut()} className='nav-link-button'>
            Wyloguj się
          </button>
        </li>
      </ul>
    </div>
  );
}

export default NavBar;
