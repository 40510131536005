import Lottie from "react-lottie";
import * as animationData from "./loadingAnimation/loadingAnimation.json";
import "./Loading.css";
const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
function Loading() {
  return (
    <div className='loading-container'>
      <Lottie
        options={defaultOptions1}
        width={250}
        height={50}
        style={{ margin: 0 }}
        isStopped={false}
        isPaused={false}
      />
      <p>Oczekiwanie...</p>
    </div>
  );
}

export default Loading;
