import { CustomError } from "../store/api/types";

export const isApiError = (error: any) => {
  const apiError =
    error &&
    error.data &&
    error.data.errors &&
    error.data.errors.length > 0 &&
    error.data;
  if (apiError) {
    return apiError as CustomError;
  }
};
