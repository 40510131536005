import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../../components/atoms/Logo/Logo";
import "./RefreshVerificationToken.css";

import Loading from "../../../components/atoms/Loading/Loading";
import { useEffect } from "react";
import { nanoid } from "@reduxjs/toolkit";
import { isApiError } from "../../../utils/apiErorr";
import { useFreshVeryficationLinkMutation } from "../../../store/api/api";

function RefreshVerificationToken() {
  const navigate = useNavigate();
  const [freshVeryfication, { isLoading, isSuccess, error }] =
    useFreshVeryficationLinkMutation();

  const { param } = useParams();

  const handleSubmit = () => {
    if (param) {
      freshVeryfication(param);
    }
  };
  const apiError = isApiError(error);

  useEffect(() => {
    if (isSuccess && !isLoading) {
      navigate("/nowy-token-sukces");
    }
  });

  return (
    <section className='form-section'>
      <div className='logo-container'>
        <Logo color={"dark"} />
      </div>
      <div className='container-form'>
        {isLoading && <Loading />}
        <div className={apiError ? "errors" : "offscreen"}>
          {apiError &&
            apiError.errors.map((singleError) => {
              return <p key={nanoid()}>- {singleError}</p>;
            })}
        </div>
        <h2 className='refresh-token-title'>
          Twój token weryfikacyjny wygasł!
        </h2>
        <p className='refresh-token-subtitle'>
          Kliknij poniższy przycisk, a zostanie wysłany kolejny email
          weryfikacyjny na Twojego maila!
        </p>
        <button onClick={handleSubmit} className='refresh-token-button'>
          Wyślij!
        </button>
      </div>
    </section>
  );
}

export default RefreshVerificationToken;
